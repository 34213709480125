<template>
  <div
    class="main-login-container page__login"
    :class="!isAsComponent ? 'page__login_route-container': ''"
  >
    <MainPage
      v-if="isHideMainPop"
      ref="mainPage"
      :is-hide-main-pop="isHideMainPop"
      :lang-text="langText"
      @BindEmail="showBindEmail"
    />
    <SDrawer
      v-else-if="isAsComponent"
      ref="aside"
      data-customSceneName="dialog_login"
      :no-header="true"
      class="login-pop-drawer"
      :show-close="false"
      :type="'full'"
      :visible="showIndex"
      :direction="'btt'"
      :immediately-render="true"
    >
      <template slot="top"></template>
      <MainPage
        :class="{ 'not-fsp-element': popConfig.activelyPopLogin }"
        ref="mainPage"
        :lang-text="langText"
        :pop-config="popConfig"
        @BindEmail="showBindEmail"
      />
    </SDrawer>
    <LoginCommonDialogs
      ref="loginCommonDialogsRef" 
      @updateSignUpInfo="updateSignUp"
    />
    <!--loading-->
    <LazyMount mount-prop-name="show">
      <s-loading
        type="curpage"
        :show="isShowLoading"
        :append-to-body="true"  
      />
    </LazyMount>
  </div>
</template>

<script>
import { mainPageMixin } from './mixin/index'
import '@login/common/common_init.js'

export default {
  name: 'LoginIndex',
  mixins: [ mainPageMixin ]
}
</script>

